import api from "../api";

export function fetchGroupsListAction(payload) {
  return api.get(
    `/groups?${[
      payload?.orderType ? `order=${payload.orderType}` : "order=desc",
      payload?.page && payload?.page > 0 ? `page=${payload.page}` : "",
      payload?.query ? `query=${payload.query}` : "",
    ]
      .filter(Boolean)
      .join("&")}`
  );
}

export function fetchCreateGroupRequest(payload) {
  return api.post(`/groups`, {
    paramObj: payload,
  });
}

export function fetchGroupUpdateAction(payload) {
  return api.put(`/groups/${payload?.group?.id}`, {
    paramObj: payload,
  });
}

export function deleteGroupRequest(payload) {
  return api.delet(`/groups/${payload?.group?.id}`, {
    paramObj: payload,
  });
}

import api from "../api";

export function fetchTeachersListAction(payload) {
  return api.get(
    `/teachers?${[
      payload?.orderType ? `order=${payload.orderType}` : "order=desc",
      payload?.page && payload?.page > 0 ? `page=${payload.page}` : "",
      payload?.query ? `query=${payload.query}` : "",
    ]
      .filter(Boolean)
      .join("&")}`
  );
}

export function fetchTeachersNonPaginatedListAction() {
  return api.get("/teachers/list_all");
}

export function fetchCreateTeacherRequest(payload) {
  return api.post(`/teachers`, {
    paramObj: payload,
  });
}

export function fetchTeacherUpdateAction(payload) {
  return api.put(`/teachers/${payload?.teacher?.id}`, {
    paramObj: payload,
  });
}

export function deleteTeacherRequest(payload) {
  return api.delet(`/teachers/${payload?.teacher?.id}`, {
    paramObj: payload,
  });
}

import api from "../api";

export function fetchClassroomsListAction(payload) {
  return api.get(
    `/classrooms?${[
      payload?.orderType ? `order=${payload.orderType}` : "order=desc",
      payload?.page && payload?.page > 0 ? `page=${payload.page}` : "",
      payload?.query ? `query=${payload.query}` : "",
    ]
      .filter(Boolean)
      .join("&")}`
  );
}

export function fetchCreateClassroomRequest(payload) {
  return api.post(`/classrooms`, {
    paramObj: payload,
  });
}

export function fetchClassroomUpdateAction(payload) {
  return api.put(`/classrooms/${payload?.classroom?.id}`, {
    paramObj: payload,
  });
}

export function deleteClassroomRequest(payload) {
  return api.delet(`/classrooms/${payload?.classroom?.id}`, {
    paramObj: payload,
  });
}

export function fetchExportClassroomRequest(payload) {
  return api.post(`/classrooms/${payload?.id}/export`, {
    paramObj: payload,
  });
}

export function fetchClassroomShowRequest(id) {
  return api.get(`/classrooms/${id}`);
}

export function fetchClassroomsNonPaginatedListAction() {
  return api.get("/classrooms/list_all");
}
